import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

// const getUserStore = (state: RootState) => state.userStore;

const getWebsiteStore = (state: RootState) => state.websiteStore;
const getDashboardUserWebsites = createSelector(getWebsiteStore, ({ dashboardUserWebsites: { data } }) => data);
const getDashboardPartnerWebsites = createSelector(getWebsiteStore, ({ dashboardPartnerWebsites: { data } }) => data);
const getDashboardWebsitesAmounts = createSelector(getWebsiteStore, ({ dashboardWebsitesAmounts: { data } }) => data);
const getDashboardWebsitesActions = createSelector(getWebsiteStore, ({ dashboardWebsitesActions: { data } }) => data);
const getAboutSingleWebsite = createSelector(getWebsiteStore, ({ websiteAbout: { data } }) => data);
const getSingleWebsiteSettings = createSelector(getWebsiteStore, ({ websiteSettings: { data } }) => data);
const getSingleWebsiteStatistics = createSelector(getWebsiteStore, ({ websiteStatistics: { data } }) => data);
const getSingleWebsiteActions = createSelector(getWebsiteStore, ({ websiteActions: { data } }) => data);
const getWebsitesPartners = createSelector(getWebsiteStore, ({ partnersWebsites: { data } }) => data);
const getWebsitesAll = createSelector(getWebsiteStore, ({ allWebsite: { data } }) => data);
const getWebsitesAllCounts = createSelector(getWebsiteStore, ({ allWebsiteCounts: { data } }) => data);
const getCustomPings = createSelector(getWebsiteStore, ({ pings: { data } }) => data);
const getErrorDetails = createSelector(getWebsiteStore, ({ errors: { data } }) => data);
const getWebinarDetails = createSelector(getWebsiteStore, ({ webinar: { data } }) => data);
const getReportData = createSelector(getWebsiteStore, ({ reportData: { data } }) => data);
const reportDataStatus = createSelector(getWebsiteStore, ({ reportData: { status } }) => status);
const getDiagnosticData = createSelector(getWebsiteStore, ({ diagnosticData: { data } }) => data);
const getDiagnosticDataStatus = createSelector(getWebsiteStore, ({ diagnosticData: { status } }) => status);
const checkWebsite = createSelector(getWebsiteStore, ({ checkWebsite: { data } }) => data);
const getCheckerList = createSelector(getWebsiteStore, ({ checkerList: { data } }) => data);
const getWpUsers = createSelector(getWebsiteStore, ({ wpUsers: { data } }) => data);
const getWpUpdatesData = createSelector(getWebsiteStore, ({ wpUpdates: { data } }) => data);
const additionalUrls = createSelector(getWebsiteStore, ({ additionalUrls: { data } }) => data);
const resources = createSelector(getWebsiteStore, ({ resources: { data } }) => data);

export {
  getWebsitesAll,
  getWebsitesAllCounts,
  getWebsitesPartners,
  getCustomPings,
  getErrorDetails,
  getWebinarDetails,
  getReportData,
  reportDataStatus,
  getDashboardUserWebsites,
  getDashboardPartnerWebsites,
  getDashboardWebsitesAmounts,
  getDashboardWebsitesActions,
  getAboutSingleWebsite,
  getSingleWebsiteSettings,
  getSingleWebsiteStatistics,
  getSingleWebsiteActions,
  getDiagnosticData,
  getDiagnosticDataStatus,
  checkWebsite,
  getCheckerList,
  getWpUsers,
  getWpUpdatesData,
  additionalUrls,
  resources,
};
