import API from './Config';
import { AxiosPromise } from 'axios';
import { IfFilesFilters } from 'Screens/FilesChanges/files.interface';

export function getDashboardUserWebsites(): AxiosPromise<any> {
  return API.get('/dashboard/user-websites');
}

export function getDashboardClientsWebsites(): AxiosPromise<any> {
  return API.get('/dashboard/clients-websites');
}

export function getDashboardWebsitesAmounts(): AxiosPromise<any> {
  return API.get('/dashboard/websites-amounts');
}

export function getDashboardClientsWebsitesAmounts(): AxiosPromise<any> {
  return API.get('/dashboard/clients-websites-amounts');
}

export function getDashboardWebsitesActions(): AxiosPromise<any> {
  return API.get('/dashboard/websites-actions');
}

export function getDashboardClientsWebsitesActions(): AxiosPromise<any> {
  return API.get('/dashboard/clients-websites-actions');
}

export function getSingleWebsite(uuid): AxiosPromise<any> {
  return API.get(`/websites/${uuid}`);
}

export function getAboutSingleWebsite(uuid): AxiosPromise<any> {
  return API.get(`/websites/about/${uuid}`);
}

export function getSingleWebsiteSettings(uuid): AxiosPromise<any> {
  return API.get(`/websites/settings/${uuid}`);
}

export function getSingleWebsiteStatistics(uuid): AxiosPromise<any> {
  return API.get(`/websites/statistics/${uuid}`);
}

export function getSingleWebsiteActions(uuid): AxiosPromise<any> {
  return API.get(`/websites/actions/${uuid}`);
}

export function getPartnersWebsites(): AxiosPromise<any> {
  return API.get(`/websites/partners`);
}

export function getWebsitesAll({ filter }): AxiosPromise<any> {
  return API.get('websites/all/list', { params: { filter: filter } });
}

export function getWebsitesAllCounts(): AxiosPromise<any> {
  return API.get(`websites/all/count`);
}

export function getFilesChanges(uuid, dataForm: IfFilesFilters): AxiosPromise<any> {
  return API.get(`websites/files/${uuid}`, { params: dataForm });
}

export function getCustomPings(uuid, dateTo, dateFrom, urlId = null): AxiosPromise<any> {
  return API.post(`websites/pings/${uuid}`, { dateTo, dateFrom, urlId });
}

export function getErrorDetails(uuid, timeStart, timeEnd, urlId): AxiosPromise<any> {
  return API.post(`websites/error/${uuid}`, { timeStart, timeEnd, urlId });
}
export function getWebinarDetails(id): AxiosPromise<any> {
  return API.get(`webinar/${id}`);
}
export function getReportData(uuid, range): AxiosPromise<any> {
  return API.get(`websites/report/${uuid}`, { params: range, responseType: 'blob' });
}

export function getDiagnosticData(websiteUuid, diagnosticsId): AxiosPromise<any> {
  return API.get(`diagnostics/${websiteUuid}`, { params: diagnosticsId && { diagnostics_id: diagnosticsId } });
}

export function runDiagnostic(websiteUuid, diagnosticMethods): AxiosPromise<any> {
  return API.get(`tasks/website-diagnostics`, { params: diagnosticMethods && { diagnostic_methods: diagnosticMethods, website_uuid: websiteUuid } });
}

export function getCheckWebsite(url, uuid): AxiosPromise<any> {
  return API.get(`checker?url=${url}${uuid ? `&uuid=${uuid}` : ''}`);
}

export function getCheckerList(page): AxiosPromise<any> {
  return API.get(`checker/list?page=${page}`);
}
export function checkerUpdate(id, phone_number): AxiosPromise<any> {
  return API.put(`checker/list/update?id=${id}`, { phone_number });
}

export function getWpUsers(uuid): AxiosPromise<any> {
  return API.get(`websites/users/${uuid}`);
}

export function editWpUserCustomPhone(data): AxiosPromise<any> {
  return API.post(`websites/users`, data);
}

export function getWpUpdates(uuid): AxiosPromise<any> {
  return API.get(`websites/wordpress-updates/${uuid}`);
}

export function storeAdditionalUrl({ uuid, url }): AxiosPromise<any> {
  return API.post(`/websites/url/${uuid}`, { url });
}

export function getAdditionalUrl(uuid): AxiosPromise<any> {
  return API.get(`websites/urls/${uuid}`);
}

export function deleteAdditionalUrl(uuid, url_uuid): AxiosPromise<any> {
  return API.delete(`websites/url/${uuid}`, { params: { url_uuid } });
}

export function getSingleWebsiteResources(uuid, type): AxiosPromise<any> {
  return API.get(`websites/resources/list`, { params: { uuid, type } });
}

export function updateSingleWebsiteResources(data): AxiosPromise<any> {
  return API.put(`updates/settings`, data);
}
