import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/WebsiteModule';
import { Toast } from 'Shared/components';

const init = () => ({
  type: User.DELETE_FILE_INIT,
});

const success = () => ({
  type: User.DELETE_FILE_SUCCESS,
});

const failure = () => ({
  type: User.DELETE_FILE_FAILURE,
});

const updateResources = (data) => async (dispatch) => {
  dispatch(init());
  Toast({ id: 'updateResources', type: 'loading', message: 'global.apex_no_data' });
  try {
    await Connector.updateSingleWebsiteResources(data);

    dispatch(success());
    Toast({ id: 'updateResources', type: 'success', message: 'global.toast.edit_user_success' });
    return true;
  } catch (err) {
    dispatch(failure());

    Toast({ id: 'updateResources', type: 'error', message: 'global.toast.edit_user_failure' });
    return false;
  }
};

export default updateResources;
