import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';
// import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: Website.GET_RESOURCES_LIST_INIT,
});

const success = (data) => ({
  type: Website.GET_RESOURCES_LIST_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.GET_RESOURCES_LIST_FAILURE,
});

const getSingleWebsiteResources = (uuid, type) => async (dispatch) => {
  try {
    dispatch(init());
    const {data} = await Connector.getSingleWebsiteResources(uuid, type);
    dispatch(success({ [type]: data }));
    // dispatch(Actions.HideDashboardWebsitesInfoLoader());
  } catch (err) {
    // dispatch(Actions.HideDashboardWebsitesInfoLoader());
    dispatch(failure());
  }
};

export default getSingleWebsiteResources;
